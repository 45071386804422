










































































import Vue, { PropType } from "vue";
import Collapse from "@/components/general/Collapse.vue";
import ExhibitionHistoryItem from "@/components/general/ExhibitionHistoryItem.vue";

export default Vue.extend({
  name: "ExhibitionHistory",
  components: {
    Collapse,
    ExhibitionHistoryItem,
  },
  props: {
    urlsItemsOnLoan: {
      type: Array as PropType<string[]>,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
  },
});
