




























import Vue from "vue";
import axios from "axios";

export default Vue.extend({
  name: "ExhibitionHistory",

  props: {
    id: {
      type: String,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      exhibitionHistoryItem: null,
    };
  },
  created() {
    this.loadExhibitionHistory();
  },
  methods: {
    loadExhibitionHistory() {
      const config = {
        params: {
          format: "json",
        },
      };
      axios
        .get(this.url, config)
        .then((response) => {
          this.exhibitionHistoryItem = response.data;
        })
        .catch(() => {
          // console.log(error);
        });
    },
  },
});
