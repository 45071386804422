


























import Vue from "vue";
export default Vue.extend({
  name: "Collapse",
  props: {
    text: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    paddingTop: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isCollapsed: false,
    };
  },
});
