
















import axios from "axios";
import Vue from "vue";

export default Vue.extend({
  name: "DetailView",
  props: {
    itemURL: {
      type: String,
      required: true,
    },
  },
  created: function () {
    this.getItem();
  },
  data() {
    return {
      item: {},
      isLoading: true,
      errorMessage: "",
    };
  },
  methods: {
    getItem(): void {
      const config = {
        params: {
          format: "json",
          inventoryNumber: this.$route.params.inventoryNumber,
        },
      };
      axios
        .get(this.itemURL, config)
        .then((response) => {
          if (response.data.results && response.data.results.length === 1) {
            this.item = response.data.results[0];
          } else {
            this.errorMessage = "Not a valid inventory number";
          }

          this.isLoading = false;
        })
        .catch((error) => {
          this.errorMessage = error.message;
          this.isLoading = false;
        });
    },
  },
});
