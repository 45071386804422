import { render, staticRenderFns } from "./RelatedItems.vue?vue&type=template&id=2b8d3722&scoped=true&"
import script from "./RelatedItems.vue?vue&type=script&lang=ts&"
export * from "./RelatedItems.vue?vue&type=script&lang=ts&"
import style0 from "./RelatedItems.vue?vue&type=style&index=0&id=2b8d3722&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b8d3722",
  null
  
)

export default component.exports