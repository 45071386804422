






































import axios from "axios";
import Vue, { PropType } from "vue";
import { Chair, Model } from "@/types";
import Collapse from "@/components/general/Collapse.vue";

export default Vue.extend({
  name: "RelatedItems",
  components: {
    Collapse,
  },
  props: {
    relatedURL: {
      type: String,
      required: true,
    },
    item: {
      type: Object as PropType<Chair | Model>,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    lgSize: {
      type: Number,
      required: true,
    },
    smSize: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      relatedItems: [],
    };
  },
  created: function () {
    if (this.item.designers.length === 0) {
      return;
    }
    if (
      this.item.designers.length === 1 &&
      this.item.designers[0].name === "Anonymous"
    ) {
      return;
    }
    const designersPK = this.item.designers.map((x) => x.pk);

    const params = new URLSearchParams();

    designersPK.forEach((pk) => params.append("designers", pk.toString()));
    params.append("format", "json");
    params.append("page_size", "100");

    const config = {
      params: params,
    };
    axios
      .get(this.relatedURL, config)
      .then((response) => {
        this.relatedItems = response.data["results"].filter(
          (relatedItem: Chair | Model) => relatedItem.pk !== this.item.pk
        );
      })
      .catch(() => {
        // console.log(error);
      });
  },
});
